<script setup lang="ts">

</script>

<template>
<svg width="100" height="32" viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2574_2755)">
<path d="M15.9121 9.57251V26.4286H12.376L3.53601 15.2881V26.4286H0V9.57251H3.53601L12.376 20.6888V9.57251H15.9121Z" fill="black"/>
<path d="M54.5475 13.7869L50.2883 26.4289H46.0559L43.3503 17.9524L40.6982 26.4289H36.4658L32.1797 13.7869H35.7692L38.7159 23.1594L41.6089 13.7869H45.1986L48.1453 23.1837L51.0652 13.7869H54.5475Z" fill="black"/>
<path d="M99.5726 13.7869L91.563 32.0476H88.2145L90.4914 26.8407L84.8391 13.7869H88.4288L92.3131 23.0384L96.0366 13.7869H99.5726Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M71.8667 26.4289V13.7869L68.3307 13.811V26.4289H71.8667Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M65.1326 13.7869H61.9179H58.3819H56.3461V16.1603H58.3819V22.2633C58.3819 25.2341 59.7928 26.7196 62.6145 26.7196C63.0966 26.7196 63.5252 26.6792 63.9002 26.5984C64.2932 26.5339 64.7129 26.4289 65.1592 26.2837V23.6439C64.5163 23.9507 63.9538 24.104 63.4715 24.104C62.4357 24.104 61.9179 23.4904 61.9179 22.2633V16.1603H65.1326V13.7869Z" fill="black"/>
<path d="M58.3818 10.3962V13.7868H61.9178V10.3962H58.3818Z" fill="black"/>
<path d="M31.486 21.0765H21.7619C21.9048 22.0452 22.2977 22.7878 22.9406 23.3046C23.6015 23.8211 24.4764 24.0796 25.5659 24.0796C26.3695 24.0796 27.2357 23.9505 28.1643 23.692C29.1108 23.4177 29.9858 23.0463 30.7895 22.578H30.8967V25.1694C30.1822 25.6699 29.3607 26.0573 28.4321 26.3318C27.5214 26.5901 26.5569 26.7194 25.539 26.7194C24.0746 26.7194 22.7977 26.453 21.7084 25.9202C20.6189 25.3874 19.7796 24.6203 19.1903 23.6193C18.6009 22.6183 18.3063 21.4479 18.3063 20.1078C18.3063 18.7999 18.5831 17.6454 19.1367 16.6444C19.7082 15.6433 20.5028 14.8685 21.5207 14.3195C22.5388 13.7704 23.7174 13.4961 25.0569 13.4961C26.3605 13.4961 27.4945 13.7625 28.4589 14.2953C29.441 14.8281 30.1911 15.595 30.709 16.596C31.2269 17.5971 31.486 18.7676 31.486 20.1078V21.0765ZM25.0569 16.0632C24.2176 16.0632 23.512 16.3134 22.9406 16.8139C22.3869 17.3144 22.012 18.0088 21.8156 18.8968H27.8428C27.6999 17.9766 27.3963 17.2742 26.9321 16.7898C26.4677 16.3055 25.8426 16.0632 25.0569 16.0632Z" fill="black"/>
<path d="M70.0987 11.8978C69.4916 11.8978 68.9737 11.712 68.545 11.3406C68.1164 10.9693 67.9021 10.485 67.9021 9.88762C67.9021 9.29024 68.1164 8.80578 68.545 8.43441C68.9737 8.06304 69.4916 7.87744 70.0987 7.87744C70.7059 7.87744 71.2238 8.06304 71.6524 8.43441C72.081 8.80578 72.2954 9.29024 72.2954 9.88762C72.2954 10.485 72.081 10.9693 71.6524 11.3406C71.2238 11.712 70.7059 11.8978 70.0987 11.8978Z" fill="#FE6703"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M74.4952 16.1603H76.5847V26.4289H80.1209V16.1603H83.3889V13.7869H80.1209H76.5847H74.4952V16.1603Z" fill="black"/>
<path d="M76.5847 12.3337V13.7868H80.1209V12.3337C80.1209 11.6716 80.2279 11.2115 80.4422 10.9532C80.6565 10.6787 81.0227 10.5414 81.5406 10.5414C82.1656 10.5414 82.8465 10.5769 83.6322 10.9483L83.6569 8.45876C83.2817 8.26487 82.8979 8.11967 82.505 8.02282C82.112 7.92596 81.6388 7.87744 81.0851 7.87744C79.6207 7.87744 78.5046 8.24881 77.7366 8.99156C76.9688 9.71807 76.5847 10.832 76.5847 12.3337Z" fill="black"/>
<mask id="mask0_2574_2755" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="62" y="-1" width="17" height="13">
<path d="M78.3221 -0.414551H62.058V11.4895H78.3221V-0.414551Z" fill="white"/>
</mask>
<g mask="url(#mask0_2574_2755)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.1875 3.18636C66.688 -0.622285 73.6905 -0.623697 78.1929 3.18636C78.3621 3.32961 78.3654 3.57202 78.202 3.72057L77.3319 4.51042C77.1759 4.65209 76.9249 4.65526 76.7627 4.51924C73.0536 1.41047 67.3262 1.41118 63.6179 4.51924C63.4557 4.65526 63.2047 4.65227 63.0487 4.51042L62.1786 3.72057C62.0149 3.57202 62.0182 3.32961 62.1875 3.18636ZM65.0398 5.82584C67.9655 3.45858 72.4114 3.45611 75.3405 5.82584C75.5157 5.96751 75.5215 6.21397 75.3549 6.36411L74.4795 7.15449C74.327 7.29227 74.0816 7.30127 73.9192 7.17301C71.7909 5.4894 68.5942 5.48569 66.4608 7.17301C66.2984 7.30127 66.053 7.2921 65.9005 7.15449L65.0254 6.36411C64.8589 6.21397 64.8643 5.96768 65.0398 5.82584Z" fill="#FE6703"/>
</g>
</g>
<defs>
<clipPath id="clip0_2574_2755">
<rect width="99.5726" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<style scoped>

</style>